import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import { useEffect, useState, useCallback, useRef } from "react";

const BnbInput = styled("input")({
  fontSize: 24,
  fontWeight: 500,
  padding: "12px 90px 12px 16px",
  textAlign: "left",
  borderRadius: 0,
  border: "1px solid #555",
  background: "white",
  width: "100%",
  outline: "none",
  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
    MozAppearance: "textfield",
  },
});

export default function PriceInput({ value, max, onChange }) {
  const [localValue, setLocalValue] = useState(value);



  const handleLocalChange = (e) => {
    setLocalValue(e.target.value);
  };

  const handleBlur = () => {
    onChange(localValue);
  };

  return (
   <Box position="relative">
        <BnbInput
        type="number"
        min={0}
        max={max}
        value={localValue}
        onChange={handleLocalChange}
        onBlur={handleBlur}
      />
      <Typography
        fontSize={24}
        fontWeight={500}
        color="black"
        style={{ marginTop: -3, position: 'relative' }}  // Retirez position: absolute
        right={18}
        top={9}
      ></Typography>
    </Box>
  );
}
